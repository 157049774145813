<template>
	<div class="full-page-container">
		<article class="standard-article page-container">
			<div class="content-container">
				<LazyBreadcrumbs
					:metadata="metadata"
					:force-show="true"
					:dark="dark || metadata.darkHeader"
					:has-background="!metadata.darkHeader"
				/>
			</div>

			<section class="container-fluids layout-width-820">
				<div class="content-container">
					<div class="introduction section-layout grid-columns-1">
						<div>
							<LinkWrap
								class="keyword"
								:to="categoryUrl"
							>
								<TnChip
									v-if="metadata.category"
									:text="metadata.category"
									size="s"
								/>
							</LinkWrap>
							<TnHeading
								v-if="metadata.articleTitle"
								data-schemaPath="metadata.articleContent.articleTitle"
								v-editable-string
								responsive
							>
								{{ metadata.articleTitle }}
							</TnHeading>
							<div
								class="ingress"
								data-schemaPath="metadata.articleContent.articleIngress"
								v-if="metadata.articleIngress"
								v-html="metadata.articleIngress"
								v-editable-string
							></div>
						</div>
					</div>
					<figure>
						<ImageResponsive
							class="article-image"
							data-schemaPath="metadata.articleContent.articleImage/"
							v-editable-string
							:style="articleImageStyle"
							v-bind="articleImage"
							v-if="articleImage"
							eager
							auto-aspect
						/>
						<figcaption
							v-if="metadata.articleImageCaption"
							class="margin-top-m"
							v-html="metadata.articleImageCaption"
						/>
					</figure>
				</div>
			</section>

			<section
				class="container-fluids layout-width-640 section-spacing-top"
				v-if="metadata.author"
			>
				<div class="content-container">
					<TnDivider
						class="section-layout"
						width="full"
					/>
					<div class="section-layout by-line padding-vertical-s">
						<div style="width: 100%">
							<div
								class="author"
								v-if="metadata.author"
							>
								<strong>Skrevet av </strong>
								<span
									data-schemaPath="metadata.articleContent.author"
									v-editable-string
									>{{ metadata.author }}</span
								>
							</div>
							<div class="details">
								<template
									v-for="(detail, idx) in details"
									:key="`detail-${idx}`"
								>
									<span>{{ detail.value }}</span>
									<TnIcon
										:key="`bullet-${idx}`"
										v-if="detail.iconAfter"
										name="bullet"
										size="s"
									/>
								</template>
							</div>
						</div>
					</div>
					<TnDivider
						class="section-layout"
						width="full"
					/>
				</div>
			</section>

			<slot />

			<section class="container-fluids layout-width-640 section-spacing-top section-spacing-bottom">
				<div class="content-container">
					<div class="section-layout">
						<div class="share">
							<span>Del denne artikkelen:</span>
							<SocialMediaBar
								:dark="dark"
								:forceBorder="false"
							/>
						</div>
					</div>
				</div>
			</section>

			<section
				v-if="relatedArticles"
				class="container-fluids layout-width-640 section-spacing-top section-spacing-bottom"
			>
				<div
					v-if="relatedArticles"
					class="container-fluids"
				>
					<div class="content-container">
						<div class="section-layout">
							<div class="related-articles">
								<ContentGrid
									:component="{ content: relatedArticles }"
									:key="$route.path"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</article>
	</div>
</template>

<script>
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel.js";
import GizmoVisualHardcodeMixin from "~/mixins/GizmoVisualHardcodeMixin.js";
import { sizeScreenDesktop } from "@/assets/tokens/js/es6";
import { usePageStore } from "~/pinia/page";
import { mapState } from "pinia";

export default defineNuxtComponent({
	name: "standardArticle",
	mixins: [GizmoComponentMixin, GizmoVisualHardcodeMixin],

	data() {
		return {
			articleImageStyle: {
				maxWidth: undefined,
			},
		};
	},

	mounted() {
		if (this.articleImage?.md) {
			const dimensions = this.articleImage.md.match(/w=(\d+)&h=(\d+)/);
			if (dimensions?.[1] < sizeScreenDesktop) this.articleImageStyle.maxWidth = `${dimensions[1]}px`;
		}
	},

	jsonld() {
		return {
			"@context": "http://schema.org",
			"@type": "Article",
			headline: this.metadata.articleTitle,
			author: {
				"@type": "Person",
				name: this.metadata.author,
			},
			articleSection: this.metadata.category,
			description: this.metadata.description,
			datePublished: this.metadata.created,
			dateModified: this.metadata.modified,
			publisher: {
				"@type": "Organization",
				name: "Telenor",
				logo: {
					"@type": "ImageObject",
					url: "https://www.telenor.no/binaries/frontend/telenor-logo-flat-blue.svg",
				},
			},
			url: "https://www.telenor.no" + this.page.publishUrl,
			image: [this.metadata.articleImage && "https://www.telenor.no" + this.metadata.articleImage.image_md.src].filter(
				(x) => x,
			),
		};
	},

	computed: {
		...mapState(usePageStore, ["page"]),
		isFixedAndTwe() {
			return this.page.schema?._id?.endsWith("fixed-tv");
		},
		darkHeader() {
			return (
				this.isFixedAndTwe &&
				["Film og serier", "T-We brukertips", "Sport", "Underholdning", "Streaming"]
					.map((e) => e.toLowerCase())
					.includes(this.metadata?.fixedTvArticleCategory?.toLowerCase())
			);
		},
		metadata() {
			return this.page.metadata;
		},
		details() {
			return [
				...(this.dateCreated
					? [{ value: this.dateCreated, iconAfter: !!this.dateModified || !!this.metadata.readTime }]
					: []),
				...(this.dateModified ? [{ value: this.dateModified, iconAfter: !!this.metadata.readTime }] : []),
				...(!this.metadata.readTime ? [{ value: this.metadata.readTime, iconAfter: false }] : []),
			];
		},
		dateCreated() {
			if (!this.metadata.created) {
				return "";
			}
			return `Publisert ${this.metadata.created}`;
			// return `Publisert ${this.metadata.created}`;
		},
		dateModified() {
			if (!this.metadata.modified) {
				return "";
			}
			return `Sist oppdatert ${this.metadata.modified}`;
		},
		divisionHubUrl() {
			const division = this.page?.schema?._id?.split("-").pop();

			return {
				mobile: "/artikler/mobil/",
				business: "/artikler/bedrift/",
				tv: "/artikler/internett-og-tv/",
				press: "/artikler/om/",
			}[division];
		},
		categoryUrl() {
			return `${this.divisionHubUrl}?category=${this.metadata?.category}`;
		},
		relatedArticles() {
			if (!this.metadata.relatedArticles?.articles?.length) return;

			return {
				heading: this.metadata.relatedArticles.heading || this.metadata.relatedArticles.header || "Relaterte artikler",
				...(this.metadata.relatedArticles.moreArticlesUrl
					? { moreArticlesUrl: this.metadata.relatedArticles.moreArticlesUrl }
					: {}),
				articles: this.metadata.relatedArticles.articles.map((article) => {
					return {
						...article,
						ingress: undefined,
					};
				}),
				scroll: true,
			};
		},
		articleImage() {
			return this.metadata.articleImage && ResponsiveImageViewModel(this.metadata.articleImage);
		},
	},
});
</script>
<style scoped lang="scss">
.standard-article {
	font-family: $font-family-telenor-base;
	text-align: left;

	.section-spacing-top {
		padding-top: $spacing-4xl;
	}

	.section-spacing-bottom {
		padding-bottom: $spacing-4xl;
	}

	.section-layout {
		margin: 0 auto;
	}

	.by-line {
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include font-text-s;
	}

	.share {
		display: flex;
		flex-direction: column;
		gap: $spacing-m;
		color: $color-neutrals-700-shade;

		@include font-text-bold-s;
	}

	.caption {
		@include font-text-xs;
	}

	.author {
		color: $color-neutrals-900-shade;
	}

	.details {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		gap: $spacing-xs;
		color: $color-neutrals-500-core;
	}

	.introduction {
		margin-top: $spacing-xl;

		@include breakpoint(mobile) {
			margin-top: $spacing-3xl;
		}
	}

	.keyword {
		margin-bottom: $spacing-l;

		@include breakpoint(mobile) {
			margin-bottom: $spacing-m;
		}
	}

	.ingress {
		&,
		p {
			padding-top: $spacing-l;
			margin-bottom: $spacing-3xl;
			color: $color-neutrals-600-shade;

			@include font-text-xl;

			@include breakpoint(mobile) {
				padding-top: $spacing-m;
				margin-bottom: $spacing-xl;
			}
		}
	}

	.category {
		text-decoration: underline;

		@include font-text-bold-s;
	}
}
</style>
